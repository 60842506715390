const GetInTouchArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 482 159"
    fill="none"
    className="w-full h-auto"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.2}
      d="M225.294 157.451c-29.475-3.233-62.332-17.268-121.694-51.87-41.724-24.368-54.137-29.737-67.904-29.774-12.792-.034-24.265 7.697-27.096 18.362-2.46 8.969-3.562 10.906-5.875 10.415-3.166-.736-2.29-9.466 1.872-16.975 8.816-16.228 26.858-20.424 51.447-12.233 10.833 3.667 33.707 15.25 55.48 28.043 60.943 35.819 87.23 46.684 120.482 49.926 28.38 2.744 58.389-11.851 76.489-37.272 5.259-7.627 5.749-8.717 4.046-9.813-27.976-16.812-47.535-37.967-57.947-62.494-3.147-7.649-3.87-10.804-3.848-18.93-.09-11.886 2.606-17.942 9.806-22.168C264.7.253 266.65.016 274.568.4c25.095 1.28 47.087 23.533 54.07 54.6 3.252 14.32 1.251 33.598-5.002 48.378-1.226 2.665 10.823 7.427 27.505 10.989 16.56 3.44 43.73 2.906 58.848-1.177 18.532-4.923 37.197-14.212 48.188-23.886l5.495-4.836-12.063.574c-9.991.58-11.94.453-11.449-1 .368-1.091 3.538-1.932 9.631-2.522 4.997-.472 11.334-1.304 14.26-2.024 2.804-.72 7.437-1.92 10.241-2.52 8.656-2.16 8.654-1.674 2.76 15.653-2.947 8.725-5.037 15.875-4.672 15.876.366.001-.002.849-.857 1.817-1.589 1.936-7.317 2.527-7.313.829.002-.485 1.967-6.665 4.301-13.572 2.333-6.907 4.298-12.844 4.299-13.087.001-.364-3.174 2.418-7.083 6.046-16.977 16.085-41.006 26.329-69.768 29.77-21.693 2.731-43.983.489-64.311-6.236-6.33-2.079-11.564-3.548-11.687-3.306-16.022 23.365-30.919 35.574-52.015 42.674-7.926 2.647-26.331 5.63-31.69 5.252-.975-.124-5.968-.623-10.962-1.242Zm95.082-65.239c4.3-13.208 4.222-29.703-.251-42.934-4.473-12.99-9.203-21.128-18.071-30.611-19.924-21.278-44.661-19.403-44.722 3.276-.047 17.95 12.317 41.511 31.274 59.633 9.601 9.122 25.408 20.686 27.603 19.964.609-.12 2.448-4.36 4.167-9.328Z"
    />
  </svg>
);

export default GetInTouchArrow;
