const ContactHeroImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={781}
    height={662}
    fill="none"
    viewBox="0 0 921 600"
    {...props}
  >
    <path
      fill="#173B50"
      d="M780.516 514.049c-2.034-23.957-20.172-60.797-32.276-80.896-12.104-20.1-25.099-40.352-30.146-63.772-5.152-23.897-1.606-49.422-7.899-72.992-7.782-29.151-29.479-51.258-52.096-68.91-22.618-17.651-47.372-32.703-66.225-55.016-20.984-24.832-33.106-56.796-48.431-86.155-15.322-29.359-36.082-58.103-65.565-68.334-16.596-5.758-33.858-4.95-50.722-.246l-.024-.176c-21.27.78-38.232 17.156-57.41 26.388-47.719 22.971-107.116.742-156.205 20.614-27.463 11.116-49.157 35.578-56.918 64.176-4.053 14.937-4.583 30.938-6.014 46.525-3.791 6.928-7.367 14.001-10.859 20.985l-23.58 47.148c-25.642 18.427-59.763 28.918-73.412 57.2-8.523 17.662-6.791 38.667-1.28 57.489 4.438 15.159 11.122 29.554 16.633 44.355-1.98 12.668-4.585 25.098-9.655 36.66-7.279 16.596-19.113 30.172-29.29 44.837C8.967 498.592.055 515.632.283 534.031l.005.282c.299 15.246 12.064 27.261 26.022 27.261h726.237c10.827 0 20.552-7.306 24.365-18.377 3.172-9.224 4.432-19.429 3.605-29.148Z"
    />
    <path
      fill="#fff"
      d="M493.783 147.544 458.4 122.098v19.251H296.32v12.389H458.4v19.25l35.383-25.444ZM296.32 104.375l35.385 25.446V110.57h162.078V98.181H331.705v-19.25l-35.385 25.444Z"
    />
    <path
      fill="#FCC14B"
      d="M147.494 318.229h-28.243l-12.606 6.277v-36.511c0-5.565 4.511-10.078 10.074-10.078h30.775c5.564 0 10.075 4.513 10.075 10.078v20.156c-.002 5.565-4.511 10.078-10.075 10.078Z"
    />
    <path
      fill="#173B50"
      d="M122.316 297.816a3.43 3.43 0 0 1-3.427 3.431 3.429 3.429 0 0 1-3.428-3.431 3.43 3.43 0 0 1 3.428-3.429 3.43 3.43 0 0 1 3.427 3.429ZM135.531 297.816a3.43 3.43 0 0 1-3.427 3.431 3.429 3.429 0 0 1-3.428-3.431 3.428 3.428 0 1 1 6.855 0ZM148.767 297.816a3.428 3.428 0 1 1-6.857 0 3.43 3.43 0 0 1 6.857 0Z"
    />
    <path
      fill="#E95F5C"
      d="M89.367 308.61c-1.734-.997-3.902-.9-5.51.288l-.027.021a50.68 50.68 0 0 0-4.389 3.644c-.344.321-5.847-5.4-6.344-5.954-5.033-5.601-8.47-12.268-10.367-19.547a50.76 50.76 0 0 0 5.098-2.558l.029-.017c1.734-.995 2.693-2.943 2.475-4.932l-1.247-11.425a5.077 5.077 0 0 0-4.372-4.481l-2.469-.332-1.693-.227c-.187.1-.37.205-.553.311a26.613 26.613 0 0 0-7.961 6.975 50.938 50.938 0 0 0 .197 19.97c2.587 12.279 9.765 23.24 19.988 30.517a50.918 50.918 0 0 0 18.206 8.196 26.633 26.633 0 0 0 10.087-4.876l.472-1.642.687-2.395a5.08 5.08 0 0 0-2.348-5.804l-9.96-5.732Z"
    />
    <path
      fill="#2B7894"
      d="M155.093 432.51c-9.813-11.545-21.1-22.713-35.489-27.453-14.389-4.74-32.512-1.144-40.44 11.768-8.607 14.016-2 33.17 9.933 44.489 11.932 11.319 27.921 17.039 42.848 23.941 31.636 14.629 60.234 35.795 83.472 61.778-11.397-41.976-32.153-81.383-60.324-114.523Z"
    />
    <path
      fill="#154F5E"
      d="M220.532 552.609c-23.058-50.61-59.954-94.829-105.61-126.574 0 0 77.042 64.171 100.494 131.573l5.116-4.999Z"
    />
    <path
      fill="#36A282"
      d="M93.425 438.044c-14.218-9.533-29.92-18.203-47.018-18.93-17.1-.726-35.695 8.753-40.347 25.229-5.05 17.885 8.022 36.673 24.472 45.309 16.45 8.636 35.59 9.935 53.939 12.85 38.887 6.173 76.482 20.388 109.728 41.489-25.29-42.13-59.967-78.586-100.774-105.947Z"
    />
    <path
      fill="#1D6D56"
      d="M201.461 548.476c-40.622-47.926-94.321-84.65-153.711-105.119 0 0 103.474 46.067 149.685 112.128l4.026-7.009Z"
    />
    <path
      fill="#36A282"
      d="M615.561 421.609c8.372-11.88 18.183-23.529 31.518-29.305s30.957-3.918 39.651 7.729c9.438 12.643 4.788 31.511-5.628 43.36s-25.192 18.7-38.851 26.589c-28.95 16.722-54.431 39.423-74.372 66.261 7.243-41.089 23.651-80.534 47.682-114.634Z"
    />
    <path
      fill="#1D6D56"
      d="M563.465 542.011c17.633-50.346 49.038-95.788 89.895-130.075 0 0-68.024 67.986-84.576 134.408l-5.319-4.333Z"
    />
    <path
      fill="#429BD5"
      d="M763.683 138.26c0 18.257-4.142 35.546-11.545 50.966a116.909 116.909 0 0 1-25.892 35.24c-.933.9-1.901 1.767-2.869 2.633a117.888 117.888 0 0 1-24.075 16.337c-1.936.985-3.888 1.92-5.858 2.769a115.721 115.721 0 0 1-23.447 7.591c-7.827 1.648-14.363 7.014-17.606 14.334l-6.723 15.251-6.69-15.149c-3.226-7.303-9.694-12.771-17.504-14.402a115.976 115.976 0 0 1-23.583-7.625 92.088 92.088 0 0 1-5.858-2.769 117.314 117.314 0 0 1-24.347-16.558 98.5 98.5 0 0 1-3.395-3.125c-.73-.696-1.443-1.393-2.156-2.106a116.715 116.715 0 0 1-12.395-14.605c-14.652-20.397-22.954-45.651-22.02-72.909 2.054-61.055 51.342-111.002 112.379-113.787 23.056-1.07 44.737 4.483 63.312 14.928 35.977 20.227 60.272 58.777 60.272 102.986Z"
    />
    <path
      fill="#8CF"
      d="M708.606 74.52c0 80.976-64.212 146.888-144.315 149.232-.73-.697-1.443-1.393-2.156-2.106a116.724 116.724 0 0 1-12.395-14.606c-14.652-20.397-22.954-45.651-22.02-72.908 2.054-61.055 51.342-111.002 112.379-113.788 23.056-1.07 44.737 4.484 63.312 14.929 3.395 12.5 5.195 25.661 5.195 39.248Z"
    />
    <path
      fill="#FF901B"
      d="M726.242 224.473a90.21 90.21 0 0 1-2.874 2.617c-14.924 13.105-33.216 22.481-53.373 26.705-7.829 1.645-14.369 7.017-17.6 14.333l-6.738 15.246-6.68-15.145c-3.231-7.296-9.691-12.767-17.499-14.394-20.335-4.242-38.767-13.719-53.789-26.962a113.886 113.886 0 0 1-5.548-5.234c9.83-32.83 38.904-57.352 74.122-60.426a85.946 85.946 0 0 1 7.531-.337c2.676 0 5.331.119 7.948.377 20.374 1.864 38.646 10.924 52.322 24.584 10.524 10.526 18.294 23.788 22.178 38.636Z"
    />
    <path
      fill="#184656"
      d="M615.738 133.443c-10.053-9.178-15.943-22.751-15.782-36.363.078-6.635 1.654-13.493 5.851-18.633 4.197-5.14 11.394-8.14 17.708-6.104 3.194-8.646 13.328-11.235 22.52-10.576 9.191.66 17.707 4.802 26.427 7.779 8.722 2.975 18.612 4.74 26.929.772-.115 12.908-6.046 26.328-15.265 35.359 1.708 7.222.083 15.149-3.907 21.406-3.989 6.257-10.58 10.793-17.847 12.287-15.863-2.138-30.77-3.785-46.634-5.927Z"
    />
    <path
      fill="#EC946E"
      d="M614.638 119.616c-1.645 3.813-6.575 5.353-11.015 3.438-4.439-1.916-6.704-6.559-5.059-10.374 1.645-3.814 6.575-5.353 11.015-3.437s6.704 6.559 5.059 10.373ZM673.372 119.616c1.645 3.813 6.575 5.353 11.015 3.438 4.439-1.916 6.704-6.559 5.059-10.374-1.645-3.814-6.576-5.353-11.015-3.437-4.438 1.916-6.703 6.559-5.059 10.373ZM651.736 146.291v19.531c0 4.262-3.463 7.727-7.742 7.727-4.261 0-7.724-3.465-7.724-7.727v-19.531h15.466Z"
    />
    <path
      fill="#C27553"
      d="M651.74 146.291v15.336c-2.496.594-5.076.9-7.742.9-2.648 0-5.246-.306-7.725-.9v-15.336h15.467Z"
    />
    <path
      fill="#EC946E"
      d="M649.913 109.295c-11.197-2.814-21.136-10.315-26.917-20.313-5.228 4.675-9.907 11.002-13.016 17.793v13.582c0 18.795 15.233 34.032 34.023 34.032 18.79 0 34.023-15.237 34.023-34.032v-13.551c-8.771 3.862-18.814 4.828-28.113 2.489Z"
    />
    <path
      fill="#CC6900"
      d="M699.297 243.434c-1.936.986-3.888 1.92-5.857 2.769l-5.128-44.751 10.985 41.982ZM602.995 201.452l-5.11 44.751a91.544 91.544 0 0 1-5.858-2.769l10.968-41.982Z"
    />
    <path
      fill="#265161"
      d="M530.915 540.547c-2.707 0-5.408-.08-8.114-.238l1.046-17.812c44.854 2.629 89.837-19.851 114.662-57.282 24.824-37.433 28.037-87.653 8.18-127.944-19.856-40.289-61.631-68.327-106.429-71.428l1.23-17.8c51.015 3.531 98.588 35.459 121.198 81.341 22.61 45.878 18.953 103.065-9.316 145.693-26.771 40.366-74.158 65.468-122.457 65.47Z"
    />
    <path
      fill="#0077C3"
      d="M418.586 261.355v18.23a7.16 7.16 0 0 1-7.158 7.16h-55.079a7.16 7.16 0 0 1-7.158-7.16v-18.23c0-5.725-4.64-10.366-10.364-10.366h-13.553c-5.724 0-10.364 4.641-10.364 10.366v59.68h137.954v-59.68c0-5.725-4.641-10.366-10.364-10.366h-13.554c-5.72 0-10.36 4.641-10.36 10.366Z"
    />
    <path fill="#51ACE6" d="M452.867 303.358H314.91v17.678h137.957v-17.678Z" />
    <path
      fill="#0077C3"
      d="M549.548 561.581H218.251c-30.018 0-52.09-28.141-44.976-57.301l26.436-108.267a44.596 44.596 0 0 1 3.039-8.611c7.47-16.049 23.684-26.714 41.936-26.714h5.993c7.59 0 14.669-2.599 20.306-7.014a32.788 32.788 0 0 0 11.647-18.07 25.226 25.226 0 0 1 5.926-11.124 25.377 25.377 0 0 1 18.795-8.305h153.093a25.38 25.38 0 0 1 15.721 5.435 25.358 25.358 0 0 1 9.016 13.994c3.582 14.724 16.774 25.084 31.936 25.084h5.993c21.342 0 39.916 14.588 44.976 35.325l26.435 108.267c7.131 29.16-14.958 57.301-44.975 57.301Z"
    />
    <path
      fill="#51ACE6"
      d="m594.527 504.277-26.434-108.268c-5.063-20.735-23.637-35.323-44.975-35.323h-5.999c-12.017 0-22.779-6.529-28.523-16.569H308.477c-9.065 0-16.413 7.35-16.413 16.418 0 9.067 7.348 16.417 16.413 16.417h147.145c17.816 0 32.259 14.448 32.259 32.268 0 17.821-14.443 32.268-32.259 32.268H253.223c-37.93 0-70.947 25.932-79.946 62.79-7.12 29.167 14.96 57.296 44.976 57.296h331.3c30.014-.001 52.094-28.131 44.974-57.297Z"
    />
    <path
      fill="#265161"
      d="M380.933 345.487h-40.089v35.128h40.089v-35.128ZM380.933 432.611h-40.089v35.128h40.089v-35.128ZM380.933 386.563h-40.089v40.101h40.089v-40.101ZM426.972 386.563h-40.089v40.101h40.089v-40.101ZM432.906 380.615h34.709v-6.714c0-15.692-12.718-28.414-28.406-28.414h-6.301v35.128h-.002ZM334.892 386.563h-34.708v40.101h34.708v-40.101ZM432.906 432.61v35.128h6.301c15.689 0 28.406-12.722 28.406-28.416v-6.714l-34.707.002ZM334.892 432.61h-34.708v6.714c0 15.694 12.718 28.416 28.406 28.416h6.301l.001-35.13ZM334.891 380.615v-35.128h-6.301c-15.688 0-28.406 12.722-28.406 28.414v6.714h34.707ZM426.972 432.611h-40.089v35.128h40.089v-35.128ZM467.615 386.563h-34.709v40.101h34.709v-40.101ZM426.972 345.487h-40.089v35.128h40.089v-35.128Z"
    />
    <path
      fill="#fff"
      d="M244.131 403.921c-2.392 13.047-9.487 22.678-15.851 21.51-6.361-1.167-9.58-12.69-7.189-25.737 2.392-13.046 9.487-22.676 15.85-21.509 6.363 1.169 9.582 12.69 7.19 25.736Z"
    />
    <path
      fill="#0077C3"
      d="M588.823 313.102h-90.664l-6.096-21.348c-9.117-24.507-32.496-40.759-58.642-40.759h-99.035c-26.129 0-49.508 16.252-58.626 40.759l-6.112 21.348h-90.664l20.612-55.416c.085-.221.17-.459.255-.679 13.243-35.037 47.148-57.896 84.585-57.896h198.952c37.42 0 71.342 22.859 84.568 57.879.085.237.17.475.255.696l20.612 55.416Z"
    />
    <path
      fill="#51ACE6"
      d="M528.92 313.102h-30.748l-6.095-21.348c-9.117-24.506-32.496-40.759-58.643-40.759H334.4c-26.13 0-49.509 16.253-58.626 40.759l-6.112 21.348h-30.748l14.584-41.54c.068-.17.119-.357.187-.527 9.372-26.256 33.362-43.392 59.849-43.392h140.783c26.469 0 50.477 17.136 59.832 43.392.068.17.136.34.186.527l14.585 41.54Z"
    />
    <path
      fill="#51ACE6"
      d="m185.14 296.565-6.152 16.542h90.661l4.728-16.542H185.14ZM493.438 296.565l4.73 16.542h90.66l-6.151-16.542h-89.239ZM288.258 374.001c-6.503 8.526-16.367 13.4-27.081 13.4h-58.388c7.47-16.049 23.685-26.714 41.936-26.714h5.994a32.878 32.878 0 0 0 20.306-7.014 32.797 32.797 0 0 0 11.647-18.071 25.22 25.22 0 0 1 5.925-11.123l5.433 19.836c2.836 10.343.73 21.161-5.772 29.686Z"
    />
    <path
      fill="#0077C3"
      d="M261.153 380.615h-71.168c-18.002 0-31.066-17.134-26.307-34.501l6.927-25.276a10.508 10.508 0 0 1 10.134-7.733h89.659c4.733 0 8.883 3.166 10.134 7.733l6.929 25.276c4.759 17.367-8.306 34.501-26.308 34.501ZM577.868 380.615H506.7c-18.002 0-31.067-17.134-26.308-34.501l6.929-25.276a10.51 10.51 0 0 1 10.135-7.733h89.658a10.51 10.51 0 0 1 10.135 7.733l6.927 25.276c4.759 17.367-8.308 34.501-26.308 34.501Z"
    />
    <path
      fill="#51ACE6"
      d="m282.573 328.268-2.036-7.429a10.508 10.508 0 0 0-10.134-7.732h-89.659a10.51 10.51 0 0 0-10.134 7.732l-2.036 7.429h113.999ZM599.264 328.268l-2.035-7.429a10.51 10.51 0 0 0-10.135-7.732h-89.658a10.51 10.51 0 0 0-10.135 7.732l-2.035 7.429h113.998Z"
    />
    <path
      fill="#0077C3"
      d="M273.128 323.68h-95.131c-6.202 0-11.231-5.03-11.231-11.234s5.027-11.235 11.231-11.235h95.131c6.202 0 11.231 5.031 11.231 11.235-.002 6.204-5.029 11.234-11.231 11.234ZM589.842 323.681h-95.13c-6.203 0-11.232-5.031-11.232-11.235s5.029-11.234 11.232-11.234h95.13c6.203 0 11.232 5.03 11.232 11.234s-5.028 11.235-11.232 11.235ZM725.24 275.506c-1.399-1.228-3.21-1.996-5.219-1.996h-41.243c-2.007 0-3.82.77-5.219 1.996l25.841 18.88 25.84-18.88Z"
    />
    <path
      fill="#2DA0EA"
      d="M673.544 275.506c-1.662 1.458-2.732 3.572-2.732 5.956v25.847c0 2.384 1.072 4.499 2.732 5.956l25.841-18.88-25.841-18.879ZM725.222 275.506l-25.839 18.881 25.839 18.88c1.662-1.458 2.732-3.572 2.732-5.956v-25.847c.002-2.386-1.07-4.5-2.732-5.958Z"
    />
    <path
      fill="#0077C3"
      d="M673.559 313.264c1.399 1.228 3.21 1.997 5.219 1.997h41.243c2.007 0 3.819-.769 5.219-1.997l-25.839-18.88-25.842 18.88Z"
    />
    <path
      fill="#E95F5C"
      d="M601.973 271.179c-8.12 8.123-8.12 21.294 0 29.415 8.121 8.123 21.288 8.123 29.407 0 8.12-8.123 8.12-21.293 0-29.415-8.119-8.123-21.286-8.123-29.407 0Zm22.508 22.513c-4.309 4.311-11.297 4.311-15.608 0-4.309-4.312-4.309-11.3 0-15.612 4.309-4.312 11.298-4.312 15.608 0 4.31 4.312 4.31 11.302 0 15.612Z"
    />
    <path
      fill="#E95F5C"
      d="m628.91 295.755-2.402 2.401 12.46 12.456 2.402-2.401-12.46-12.456Z"
    />
    <path
      fill="#E95F5C"
      d="M652.933 322.158a5.198 5.198 0 0 1-7.356 0l-9.283-9.286a5.202 5.202 0 0 1 0-7.359 5.198 5.198 0 0 1 7.356 0l9.283 9.286a5.204 5.204 0 0 1 0 7.359Z"
    />
    <path
      fill="#E0F4FF"
      d="M439.211 71.083h141.497c-.379-5.25-3.089-10.288-7.388-13.332-4.478-3.17-10.46-4.11-15.694-2.462 2.973-8.796-2.053-19.78-10.225-24.186-8.171-4.405-19.363-2.055-25.073 5.267 1.48-9.8-4.338-20.102-12.678-25.455-8.338-5.353-20.236-4.747-27.598 1.887-7.362 6.634-9.066 18.899-3.312 26.968-5.036-2.631-12.147-.208-16.748 3.126-4.601 3.334-7.078 9.37-6.149 14.974-7.202.705-14.14 6.338-16.632 13.213Z"
    />
    <path
      fill="#429BD4"
      d="M258.831 118.937c0 18.256-4.126 35.545-11.529 50.966a118.355 118.355 0 0 1-28.778 37.872c-14.924 13.094-33.209 22.469-53.362 26.698-7.827 1.647-14.381 7.014-17.607 14.334l-6.74 15.251-6.673-15.149c-3.226-7.303-9.694-12.772-17.504-14.402a117.522 117.522 0 0 1-49.322-23.233 93.005 93.005 0 0 1-4.466-3.719c-6.706-5.911-12.75-12.551-17.962-19.837-14.636-20.396-22.938-45.65-22.004-72.908C24.938 53.755 74.226 3.808 135.246 1.023c18.268-.85 35.671 2.462 51.359 9.052 42.447 17.9 72.226 59.9 72.226 108.862Z"
    />
    <path
      fill="#8CF"
      d="M217.047 89.93c0 68.051-57.149 123.195-127.642 123.195-7.539 0-14.924-.628-22.09-1.851a92.955 92.955 0 0 1-4.465-3.719c-6.706-5.911-12.75-12.551-17.962-19.837-14.636-20.396-22.938-45.65-22.004-72.908C24.938 53.755 74.226 3.808 135.246 1.023c18.268-.85 35.671 2.462 51.359 9.052 18.982 21.5 30.442 49.386 30.442 79.854Z"
    />
    <path
      fill="#184656"
      d="M247.31 169.903a118.015 118.015 0 0 1-28.777 37.865 117.366 117.366 0 0 1-53.372 26.705c-7.829 1.645-14.369 7.017-17.6 14.334l-6.739 15.245-6.679-15.145c-3.231-7.296-9.691-12.766-17.5-14.393-20.334-4.243-38.766-13.719-53.788-26.961-6.698-5.909-12.744-12.549-17.957-19.825 1.545-5.908 4.459-11.657 7.85-16.692 6.896-10.27 15.874-19.349 20.492-30.847 6.362-15.859 3.528-33.861 6.858-50.632 3.727-18.654 15.538-35.564 31.77-45.498 16.231-9.91 36.664-12.706 54.956-7.492 15.856 4.5 30.522 15.661 35.774 31.282 6.718 19.983-2.617 43.139 5.747 62.488 5.767 13.342 18.669 21.866 29.907 31.084 3.132 2.573 6.224 5.408 9.058 8.482Z"
    />
    <path
      fill="#FF901B"
      d="M218.529 207.775a117.062 117.062 0 0 1-35.705 21.501 106.653 106.653 0 0 1-8.88 2.972 109.008 109.008 0 0 1-8.778 2.225c-7.827 1.647-14.38 7.014-17.606 14.334l-6.74 15.25-6.673-15.149c-3.226-7.302-9.694-12.771-17.504-14.401a110.783 110.783 0 0 1-9.203-2.327 103.986 103.986 0 0 1-8.845-2.989c-13.226-5.061-25.298-12.449-35.74-21.636 10.629-30.044 38.151-52.088 71.173-54.72 2.174-.187 4.381-.255 6.622-.255 2.241 0 4.448.068 6.638.255 20.154 1.613 38.269 10.444 51.716 23.912 8.659 8.662 15.399 19.225 19.525 31.028Z"
    />
    <path
      fill="#CC6900"
      d="M158.084 160.307a8.925 8.925 0 0 1-1.101 12.571 8.918 8.918 0 0 1-12.566-1.101c-3.167-3.775-5.635-12.936-1.861-16.103 3.772-3.168 12.361.857 15.528 4.633Z"
    />
    <path
      fill="#CC6900"
      d="M123.212 160.307a8.924 8.924 0 0 0 1.101 12.571 8.919 8.919 0 0 0 12.567-1.101c3.166-3.775 5.635-12.936 1.861-16.103-3.774-3.168-12.363.857-15.529 4.633Z"
    />
    <path
      fill="#EC946E"
      d="M147.283 137.279v23.946a6.638 6.638 0 0 1-6.638 6.64c-3.667 0-6.622-2.972-6.622-6.64v-23.946h13.26Z"
    />
    <path
      fill="#C27553"
      d="M147.283 137.279v11.379a33 33 0 0 1-6.638.662c-2.258 0-4.482-.221-6.622-.645v-11.396h13.26Z"
    />
    <path
      fill="#EC946E"
      d="M151.976 89.253c-8.36-6.647-14.731-15.737-18.223-25.828h-1.083c-.437 8.46-4.381 16.479-10.316 22.664-4.284 4.465-9.79 7.705-15.729 9.427v11.523c0 18.795 15.233 34.033 34.023 34.033 18.79 0 34.022-15.238 34.022-34.033v-6.961c-8.242-1.88-16.076-5.562-22.694-10.825Z"
    />
    <path
      fill="#CC6900"
      d="M107.447 194.019v38.161a104 104 0 0 1-8.845-2.989l8.845-35.172ZM182.825 229.276a106.777 106.777 0 0 1-8.88 2.972v-38.229l8.88 35.257Z"
    />
    <path
      fill="#E0F4FF"
      d="M209.957 71.083h141.497c-.379-5.25-3.088-10.289-7.387-13.332-4.479-3.17-10.461-4.11-15.695-2.462 2.973-8.796-2.053-19.78-10.224-24.186-8.172-4.406-19.364-2.055-25.074 5.266 1.481-9.799-4.338-20.101-12.678-25.454-8.338-5.353-20.236-4.747-27.598 1.887-7.361 6.633-9.064 18.899-3.312 26.967-5.036-2.63-12.146-.207-16.748 3.127-4.601 3.334-7.078 9.37-6.149 14.974-7.202.705-14.14 6.338-16.632 13.213Z"
    />
  </svg>
)
export default ContactHeroImage